<template>
  <q-dialog
      v-model="isOpen"
      persistent
  >
    <q-card style="min-width: 50vw;">
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ $t('Selected products') }}
        </div>

        <q-btn
            color="negative"
            size="sm"
            class="q-ml-sm"
            :label="$t('Delete all')"
            @click="handleDeleteAll"
        />

        <q-space/>

        <q-btn
            color="transparent"
            text-color="dark"
            size="sm"
            icon="close"
            no-caps
            unelevated
            @click="hide"
        />
      </q-card-section>

      <q-card-section v-if="items.length > 0">
        <q-list bordered separator>
          <q-item
              v-for="item in items"
              :key="item.id"
              v-ripple
          >
            <q-item-section avatar>
              <img
                  style="max-height: 50px; width: 60px; object-fit: contain;"
                  :src="item.image || fallbackImage"
                  @error="onImageLoadFailure"
              >
            </q-item-section>

            <q-item-section>
              {{ `${item.name} (${item.id})` }}
            </q-item-section>

            <q-item-section class="text-center">
              <q-input
                  standout="bg-teal text-white"
                  type="number"
                  :model-value="item.count"
                  :label="$t('Quantity')"
                  @update:model-value="updateItem(item.id, 'count', $event)"
              />
            </q-item-section>

            <q-item-section style="max-width: 80px;">
              <q-btn
                  color="negative"
                  size="sm"
                  :label="$t('Delete')"
                  @click="handleDelete(item.id)"
              />
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>

      <q-card-section v-else class="q-pa-lg text-center text-subtitle1">
        {{ $t('No products') }}
      </q-card-section>

      <q-card-section class="text-center">
        <q-btn
            color="dark"
            text-color="white"
            class="q-mr-sm"
            :label="$t('Cancel')"
            @click="hide"
        />

        <q-btn
            color="light-blue-9"
            text-color="white"
            :label="$t('Add')"
            :disable="items.length <= 0"
            @click="save"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

export default {
  name: 'AcceptanceItemsModal',
  emits: ['save'],
  data () {
    return {
      fallbackImage: 'assets/img/fallback-image/package.png',
      isOpen: false,
      items: [],
      warehouse: null
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification',
      'setAcceptance',
      'setCleanAcceptanceItems',
      'setAcceptanceItems',
      'setCleanAcceptanceItems',
      'updateAcceptanceEmbedded',
      'setNewAcceptance'
    ]),
    handleDeleteAll () {
      this.items = []
    },
    show (items) {
      this.items = [...items]
      let testItem = this.items[0]
      let shop = {}

      if (testItem?._embedded?.productOffer?._embedded?.virtualShop) {
        shop = testItem?._embedded?.productOffer?._embedded?.virtualShop.id
      } else if (testItem?._embedded?.productOffer?._embedded?.shop) {
        shop = testItem?._embedded?.productOffer?._embedded?.shop.id
      } else {
        shop = testItem?._embedded?.shop.id
      }

      this.$service.shop.get(shop)
          .then(shop => {
            const query = {
              per_page: 250,
              page: 1,
              filter: [
                { type: 'eq', field: 'state', value: 'active' }
              ]
            }

            if (shop && shop._embedded.owner) {
              query.filter.push({ type: 'eq', field: 'owner', value: shop._embedded.owner.id })
            }

            return this.$service.warehouse.getAll(query)
                .then(({ items, totalItems }) => {
                  if (totalItems === 1) {
                    this.warehouse = items[0]
                  }
                  return this.warehouse
                })
          })

      this.isOpen = true
    },
    hide () {
      this.isOpen = false
    },
    save () {
      const items = this.items
      if (items) {
        this.$emit('save', this.items)
        this.setAcceptance(null)
        this.setAcceptanceItems([])
        this.setCleanAcceptanceItems([])
        this.setNewAcceptance()
        this.updateAcceptanceEmbedded({ warehouse: this.warehouse })

        this.setAcceptanceItems(items.map(x => {
          return {
            count: x.count,
            _embedded: {
              productOffer: x._embedded.productOffer
            },
          }
        }))
        this.$router.push('/inbound/purchase-orders/entity/unknown?hasItems=true')
      }
    },
    handleDelete (id) {
      this.items = this.items.filter(x => x.id !== id)
    },
    updateItem (id, field, event) {
      this.items = this.items.map(item => {
        if (item.id !== id) {
          return item
        }

        return { ...item, [field]: event }
      })
    },
    onImageLoadFailure (e) {
      e.target.src = this.fallbackImage
    }
  }
}
</script>
