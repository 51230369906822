<template>
  <q-tr
    v-if="data"
    :props="data"
  >
    <q-td
      key="extId"
      :props="data"
      auto-width
    >
      <div>
        {{ data.row.extId }}

        <q-popup-edit
          :model-value="data.row.extId"
          :title="$t('Edit extId')"
        >
          <q-input
            v-model="data.row.extId"
            dense
            autofocus
            counter
          />
        </q-popup-edit>
      </div>
    </q-td>

    <q-td
      key="source"
      :props="data"
      auto-width
    >
      <div>
        {{ getTitleOfObject(data.row.sourceId) }}

        <q-popup-edit
          :model-value="data.row.sourceId"
          :title="$t('Edit source')"
        >
          <form-builder :schema="sourceSchema" />
        </q-popup-edit>
      </div>
    </q-td>

    <q-td
      key="recipient"
      :props="data"
      auto-width
    >
      <h6 class="q-ma-none">
        {{ data.row.name }}

        <q-popup-edit
          :model-value="data.row.name"
          :title="$t('Edit name')"
        >
          <q-input
            v-model="data.row.name"
            dense
            autofocus
            counter
          />
        </q-popup-edit>
      </h6>

      <strong>
        {{ data.row.phone }}

        <q-popup-edit
          :model-value="data.row.phone"
          :title="$t('Edit phone')"
        >
          <q-input
            v-model="data.row.phone"
            dense
            autofocus
            counter
          />
        </q-popup-edit>
      </strong>

      <p class="q-ma-none">
        {{ data.row.email }}

        <q-popup-edit
          :model-value="data.row.email"
          :title="$t('Edit email')"
        >
          <q-input
            v-model="data.row.email"
            dense
            autofocus
            counter
          />
        </q-popup-edit>
      </p>
    </q-td>

    <q-td
      key="orderData"
      :props="data"
      auto-width
    >
      <p class="q-ma-none">
        {{ $t('Warehouse') + ': ' + (getTitleOfObject(data.row.warehouse)) }}
      </p>

      <p class="q-ma-none">
        {{ $t('Shop') + ': ' + getTitleOfObject(data.row.shop) }}
      </p>

      <q-popup-edit
        :model-value="data.row.warehouse"
        :title="$t('Order data')"
      >
        <form-builder :schema="warehouseSchema" />
      </q-popup-edit>
    </q-td>

    <q-td
      key="paymentState"
      :props="data"
      auto-width
    >
      {{ getTitleOfObject(data.row.paymentState) }}

      <q-popup-edit
        :model-value="data.row.paymentState"
        :title="$t('Payment state')"
      >
        <form-builder :schema="paymentStateSchema" />
      </q-popup-edit>
    </q-td>

    <q-td
      key="totalPrice"
      :props="data"
      auto-width
    >
      {{ data.row.products.reduce((acc, product) => acc + (Number(product.price || 0) * Number(product.count)), 0) }}
    </q-td>

    <q-td
      key="address"
      :props="data"
      auto-width
    >
      <h6 class="q-ma-none">
        <span v-if="data.row.locality">
          {{ data.row.postcode + ' - ' + getTitleOfObject(data.row.locality) }}
        </span>

        <span v-else>
          {{ $t('No locality') }}
        </span>

        <q-popup-edit
          :model-value="data.row.locality"
          :title="$t('Edit locality')"
        >
          <form-builder :schema="localitySchema" />
        </q-popup-edit>
      </h6>

      <strong>
        {{ data.row.street + ' ' + data.row.house }}

        <q-popup-edit
          :model-value="data.row.street"
          :title="$t('Edit street')"
        >
          <q-input
            v-model="data.row.street"
            dense
            autofocus
            counter
          />
        </q-popup-edit>
      </strong>

      <p class="q-ma-none">
        {{ data.row.house }}

        <q-popup-edit
          :model-value="data.row.house"
          :title="$t('Edit house')"
        >
          <q-input
            v-model="data.row.house"
            dense
            autofocus
            counter
          />
        </q-popup-edit>
      </p>
    </q-td>

    <q-td
      key="params"
      :props="data"
      auto-width
    >
      <h6 class="q-ma-none">
        {{ $t('Sender') + ' - ' + getTitleOfObject(data.row.sender) }}
      </h6>

      <div>
        <strong>
          {{ $t('Delivery Service') + ' - ' + getTitleOfObject(data.row.deliveryService) }}
        </strong>
      </div>

      <strong>
        {{ $t('Rate') + ' - ' + getTitleOfObject(data.row.rate) }}
      </strong>

      <p class="q-ma-none">
        {{ $t('Service point') + ' - ' + getTitleOfObject(data.row.servicePoint) }}
      </p>

      <p class="q-ma-none">
        {{ $t('Shipment Date') + ' - ' + $moment(getTitleOfObject(data.row.shipmentDate)).format(appOptions.formats.date)}}
      </p>
      <q-popup-edit
        :model-value="data.row.sender"
        :title="$t('Edit params')"
      >
        <form-builder :schema="paramsSchema" />
      </q-popup-edit>
    </q-td>

    <q-td
      key="products"
      :props="data"
      auto-width
    >
      <table class="table table-valign-middle">
        <thead>
          <tr>
            <th>{{ $t('Offer') }}</th>

            <th>{{ $t('Name') }}</th>

            <th>{{ $t('Price') }}</th>

            <th>{{ $t('Count') }}</th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="item in data.row.products"
            :key="item.id"
          >
            <td>{{ item.offer }}</td>

            <td>{{ item.name }}</td>

            <td>{{ item.price }}</td>

            <td>{{ item.count }}</td>
          </tr>
        </tbody>
      </table>
    </q-td>

    <q-td
      key="actions"
      :props="data"
      auto-width
    >
      <q-btn
        color="light-blue-9"
        text-color="white"
        size="sm"
        :label="$t('Save')"
        no-caps
        unelevated
        @click="save(data.row)"
      />
    </q-td>
  </q-tr>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'OrderImportRow',
  emits: ['change', 'save'],
  props: {
    tableProps: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      data: null,
      paymentsStates: [
        { id: 'paid', name: this.$t('Paid') },
        { id: 'not_paid', name: this.$t('Not paid') }
      ],
      isFocusedPostcode: false
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    sourceSchema () {
      return {
        groups: [
          {
            styleClasses: 'row locality-min-width',
            fields: [
              {
                type: 'multiselect',
                label: this.$t('Source'),
                field: 'sourceId',
                value: this.data.row.sourceId,
                customLabel: (row) => {
                  if (row && typeof row === 'object') {
                    return `${row.name || this.$t('No name')} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: []
                  }

                  return this.$service.iSource.getAll(query)
                },
                onChange: value => {
                  this.$emit('change', { ...this.data.row, sourceId: value.id })
                }
              }
            ]
          }
        ]
      }
    },
    paymentStateSchema () {
      return {
        groups: [
          {
            fields: [
              {
                type: 'select',
                field: 'paymentState',
                value: this.data.row.paymentState,
                label: this.$t('Payment Status'),
                options: this.paymentsStates,
                required: true,
                customLabel (row) {
                  return row && typeof row === 'object'
                    ? row.name
                    : row
                },
                onChange: value => {
                  this.$emit('change', { ...this.data.row, paymentState: value.id })
                }
              }
            ]
          }
        ]
      }
    },
    paramsSchema () {
      return {
        groups: [
          {
            fields: [
              {
                type: 'multiselect',
                label: this.$t('Sender'),
                wrapperStyleClasses: 'q-pa-xs',
                field: 'sender',
                value: this.data.row.sender,
                required: true,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.sender.getAll(query)
                },
                onChange: sender => {
                  this.$emit('change', { ...this.data.row, sender: sender.id })
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Delivery Service'),
                wrapperStyleClasses: 'q-pa-xs',
                field: 'deliveryService',
                value: this.data.row.deliveryService,
                required: true,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                imagePreview: (row) => {
                  if (!row || !row._embedded?.deliveryService) {
                    return ''
                  }

                  return `${this.appOptions.defaultServer}${row._embedded.deliveryService.logo}`
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    'order-by': [
                      { type: 'field', field: 'name', direction: 'desc' }
                    ],
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.deliveryService.getAll(query)
                },
                onChange: (deliveryService) => {
                  const data = {
                    ...this.data.row,
                    deliveryService,
                    servicePoint: null
                  }

                  this.$emit('change', data)
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Rate'),
                wrapperStyleClasses: 'q-pa-xs',
                field: 'rate',
                value: this.data.row.rate,
                required: true,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                imagePreview: (row) => {
                  if (!row || !row._embedded.deliveryService) {
                    return ''
                  }

                  return `${this.appOptions.defaultServer}${row._embedded.deliveryService.logo}`
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ],
                    'order-by': [
                      { type: 'field', field: 'deliveryService', direction: 'desc' },
                      { type: 'field', field: 'name', direction: 'desc' }
                    ]
                  }

                  return this.$service.deliveryServiceRate.getAll(query)
                },
                onChange: (rate) => {
                  const data = {
                    ...this.data.row,
                    rate,
                    servicePoint: null
                  }

                  this.$emit('change', data)
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Service point'),
                wrapperStyleClasses: 'q-pa-xs',
                field: 'servicePoint',
                value: this.data.row.servicePoint,
                required: true,
                values: this.loadedServicePoints,
                disabled: !this.data.row.rate || typeof this.data.row.rate === 'string' || (typeof this.data.row.rate === 'object' && !this.data.row.rate.id),
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                description: (row) => {
                  if (!row) {
                    return ''
                  }

                  return `${row.rawAddress}, ${this.$t('extId')}: ${row.extId}, ${this.$t('phone')}: ${row.phone || 'none'}`
                },
                imagePreview: (row) => {
                  if (!row || !row._embedded.deliveryService) {
                    return ''
                  }

                  return `${this.appOptions.defaultServer}${row._embedded.deliveryService.logo}`
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  if (
                    this.data.row.rate &&
                    this.data.row.rate._embedded &&
                    this.data.row.rate._embedded.deliveryService
                  ) {
                    query.filter.push(this.createDeliveryServiceFilter(this.data.row.rate._embedded.deliveryService))
                  }

                  if (this.data.row.locality && typeof this.data.row.locality === 'object') {
                    query.filter.push({
                      type: 'eq',
                      field: 'locality',
                      value: this.data.row.locality.id
                    })
                  }

                  return this.$service.servicePoint.getAll(query)
                },
                onChanged: model => {
                  this.$emit('change', model)
                }
              },
              {
                type: 'date',
                value: this.data.row.shipmentDate,
                label: this.$t('Shipment Date'),
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: model => {
                  this.$emit('change', { ...this.data.row, shipmentDate: this.$moment(model).format(this.appOptions.formats.date) })
                }
              },
            ]
          }
        ]
      }
    },
    localitySchema () {
      return {
        groups: [
          {
            fields: [
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Postcode'),
                field: 'postcode',
                value: this.data.row.postcode,
                debounce: 300,
                required: true,
                wrapperStyleClasses: 'q-pa-xs',
                onFocus: e => {
                  this.isFocusedPostcode = true
                },
                onFocusOut: e => {
                  this.isFocusedPostcode = false
                },
                onInput: postcode => {
                  this.$emit('change', { ...this.data.row, postcode })
                  this.loadLocalitiesByPostcode(postcode)
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Location'),
                wrapperStyleClasses: 'q-pa-xs',
                field: 'locality',
                value: this.data.row.locality,
                disabled: this.isFocusedPostcode,
                required: true,
                minimumLength: 3,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return row.type + ' ' + row.name + (row.postcode ? ', ' + row.postcode : '') + (row._embedded && row._embedded.area.name ? ' (' + row._embedded.area.name + ')' : '')
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.locality.getAll(query)
                },
                onChange: locality => {
                  this.$emit('change', { ...this.data.row, locality })
                }
              }
            ]
          }
        ]
      }
    },
    warehouseSchema () {
      return {
        groups: [
          {
            fields: [
              {
                type: 'multiselect',
                label: this.$t('Warehouse'),
                field: 'warehouse',
                value: this.data.row.warehouse,
                wrapperStyleClasses: 'q-pa-xs',
                required: true,
                customLabel: (row) => {
                  if (row && typeof row === 'object') {
                    return `${row.name || this.$t('No name')} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.warehouse.getAll(query)
                },
                onChange: value => {
                  this.$emit('change', { ...this.data.row, warehouse: value.id })
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Shop'),
                field: 'shop',
                value: this.data.row.shop,
                wrapperStyleClasses: 'q-pa-xs',
                required: true,
                customLabel: (row) => {
                  if (row && typeof row === 'object') {
                    return `${row.name || this.$t('No name')} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'in', field: 'state', values: ['active', 'blocked'] }
                    ]
                  }

                  return this.$service.shop.getAll(query)
                },
                onChange: value => {
                  this.$emit('change', { ...this.data.row, shop: value.id })
                }
              }
            ]
          }
        ]
      }
    }
  },
  watch: {
    tableProps (value) {
      this.data = value
    }
  },
  mounted () {
    this.data = this.tableProps
  },
  methods: {
    ...mapMutations([
      'addOrderToList',
      'addErrorNotification'
    ]),
    getTitleOfObject (obj) {
      if (!obj) {
        return this.$t('No data')
      }

      return typeof obj === 'object'
        ? `${obj.name} (${obj.id})`
        : obj
    },
    save (row) {
      this.$emit('save', row)
    },
    loadLocalitiesByPostcode (value) {
      if (value.length < 4) {
        return
      }

      const query = {
        page: 1,
        per_page: 1,
        filter: [
          { type: 'eq', field: 'extId', value }
        ]
      }

      return this.$service.postcode.getAll(query)
        .then(({ items }) => {
          if (items.length === 0) {
            this.addErrorNotification('No localities found!')
            return
          }

          this.$emit('change', { ...this.data.row, postcode: value, locality: items[0]._embedded.locality })
        })
    },
    createDeliveryServiceFilter (deliveryService) {
      if (deliveryService.id) {
        return { type: 'eq', field: 'deliveryService', value: deliveryService.id }
      }

      const link = deliveryService._links.self.href
      const index = link.lastIndexOf('/')
      const value = link.slice(index + 1)

      return { type: 'eq', field: 'deliveryService', value }
    }
  }
}
</script>
