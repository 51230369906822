<template>
  <div>
    <div class="row justify-center">
      <div class="text-h6 text-center q-mb-sm" style="max-width: 500px">
        {{
          $t(
              'The external ID for the order must be the identical to the order ID in your ecommerce platform.'
          )
        }}
      </div>
    </div>

    <form-builder ref="formBuilder" :schema="schema" class="q-px-md"/>

    <div class="text-center q-py-ms">
      <q-btn
          color="light-blue-9"
          text-color="white"
          size="md"
          :label="$t('Import')"
          no-caps
          unelevated
          @click="save"
      />
    </div>
  </div>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

// Helpers
import { extractId } from '../../helpers/helpers'

export default {
  name: 'WholesaleForm',
  emits: ['close'],
  data () {
    return {
      type: 'wholesale',
      extIDMultiple: false,
      rawModel: '',
      order: {
        extId: '',
        state: 'download',
        eav: null,
        type: 'wholesale',
        source: null,
        shop: null,
        date: new Date().toJSON().slice(0, 10).replace(/-/g, '/')
      }
    }
  },
  computed: {
    schema () {
      return {
        groups: [
          {
            id: 'form',
            styleClasses: 'row',
            fields: [
              {
                type: 'select',
                label: this.$t('Type'),
                field: 'type',
                value: this.type,
                required: true,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                options: Object.values(window.appOptions?.orderTypes),
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.label} (${row.type})`
                  }

                  return row
                },
                onChange: (type) => {
                  this.order = { ...this.order, ...type.options }
                  this.type = `${type.label} (${type.type})`
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Store'),
                field: 'shop',
                value: this.order.shop,
                required: true,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { field: 'state', type: 'in', values: ['active', 'blocked'] }
                    ]
                  }

                  return this.$service.shop.getAll(query)
                },
                onChange: (shop) => {
                  this.order.shop = shop
                  this.order.source = null
                  this.resetSource()
                }
              },
              {
                ref: 'source',
                type: 'multiselect',
                label: this.$t('Ecommerce platform'),
                field: 'source',
                value: this.order.source,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                required: true,
                disabled: !this.order.shop,
                customLabel: row => {
                  if (row && typeof row === 'object') {
                    return `${row.name || this.$t('No name')} (${row.id}) / ${row.handler} / ${row._embedded.owner.name}`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'in', field: 'state', values: ['active', 'blocked'] },
                      { type: 'eq', field: 'owner', value: this.order.shop._embedded.owner.id }
                    ]
                  }

                  return this.$service.iSource.getAll(query)
                },
                onChange: (source) => {
                  this.order.source = source
                }
              },
              {
                if: !this.extIDMultiple,
                type: 'input',
                inputType: 'text',
                label: this.$t('Write the External ID'),
                field: 'extId',
                value: this.order.extId,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: (extId) => {
                  this.order.extId = extId
                },
                btnStyleClasses: 'q-pl-sm',
                buttons: [
                  {
                    id: 'switch',
                    icon: 'change_circle',
                    color: 'teal',
                    size: 'lg',
                    onClick: () => {
                      this.extIDMultiple = true
                    }
                  }
                ]
              },
              {
                if: this.extIDMultiple,
                type: 'input',
                inputType: 'textarea',
                label: this.$t('Write the External IDs'),
                field: 'extId',
                value: this.rawModel,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: (extId) => {

                  this.rawModel = extId

                  const lines = extId.split('\n')
                  const result = []

                  for (const line of lines) {
                    const parts = line.split(' ')
                    const sku = parts[0]
                    const rootMatch = sku.match(/[\d-\+]+/)

                    let rootValue = null
                    if (rootMatch) {
                      rootValue = rootMatch[0] // Extract numbers and hyphens from the first column
                    }

                    result.push(sku)
                  }
                  this.order.extId = result
                },
                btnStyleClasses: 'q-pl-sm',
                buttons: [
                  {
                    id: 'switch',
                    icon: 'change_circle',
                    color: 'teal',
                    size: 'lg',
                    onClick: () => {
                      this.extIDMultiple = false
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },
  mounted () {
    const query = {
      per_page: 25,
      page: 1,
      filter: [
        { field: 'state', type: 'in', values: ['active', 'blocked'] }
      ]
    }

    this.$service.shop.getAll(query)
        .then(({ items }) => {
          if (items.length === 1) {
            this.order.shop = items[0]

            const sQuery = {
              per_page: 25,
              page: 1,
              filter: [
                { type: 'in', field: 'state', values: ['active', 'blocked'] },
                { type: 'eq', field: 'owner', value: items[0]._embedded.owner.id }
              ]
            }

            return this.$service.iSource.getAll(sQuery)
                .then(({ items }) => {
                  if (items.length === 1) {
                    this.order.source = items[0]
                  }
                })
          }
        })
  },
  methods: {
    ...mapMutations([
      'addOrderToList',
      'setOrder',
      'addErrorNotification'
    ]),
    getFormRef () {
      return this.$refs.formBuilder.$refs.form[0].$refs
    },
    resetSource () {
      return this.getFormRef().source[0].$refs.reset
    },
    import (data) {
      let item = {}
      if(!data.extId && Array.isArray(this.order.extId)) {
        item = {
          source: data.source,
          sourceData: {
            asyncMode: {
              'Orderadmin\\Products\\Entity\\AbstractOrder': true
            },
            filters: {
              extId: this.order.extId
            }
          }
        }
      } else {
        item = {
          source: data.source,
          sourceData: {
            asyncMode: {
              'Orderadmin\\Products\\Entity\\AbstractOrder': true
            }
          }
        }
      }
      return this.$service.integrationImport.save(item)
          .then(() => {
            this.$emit('close')
          })
    },
    save () {
      const item = Object.keys(this.order).reduce((acc, key) => {
        acc[key] = extractId(this.order[key])
        return acc
      }, {})

      const error = this.$utils.validate.wholesaleOrder(item)

      if (error) {
        this.addErrorNotification(error)
        return
      }

      if (!item.extId) {
        return this.import(item)
      }
      if(this.order.eav) {
          item.eav = this.order.eav
      }
      return this.$service.order.save(item, undefined, this.order.type)
          .then(order => {
            this.addOrderToList(order)
            this.$emit('close')
          })
    }
  }
}
</script>
