<template>
  <q-tr
      :props="data"
      class="clickable"
      :style="isLockedOrder && 'opacity: 0.7;'"
      @dblclick="handleDBClick"
  >
    <td class="q-td text-left">
      <q-checkbox
          size="xl"
          :model-value="!!items.find(({id}) => id === data.row.id)"
          color="amber-6"
          @click="handleCheck(data.row)"
      />
    </td>

    <q-td
        key="number"
        :props="data"
        auto-width
    >
      {{ data.rowIndex + 1 }}
    </q-td>

    <q-td
        key="id"
        :props="data"
    >
      <div class="row items-center">
        <h5 class="text-subtitle1 q-ma-none q-mr-sm text-weight-bold">
          {{ data.row.id }}
        </h5>

        <q-icon
            :name="isLockedOrder ? 'lock' : 'lock_open'"
            :color="isLockedOrder ? 'negative' : 'positive'"
        />
      </div>

      <h6 class="q-my-none text-subtitle2">
        <strong>{{ data.row.extId }}</strong>
      </h6>

      <p v-if="data.row.clientId" class="q-ma-none text-caption">
        {{ data.row.clientId }}
      </p>

      <p class="q-ma-none text-caption">
        {{
          $moment((typeof data.row.created === 'object' ? data.row.created.date : data.row.created)).format(appOptions.formats.fullDate)
        }}
      </p>

      <p class="q-ma-none text-caption">
        {{ $t('Type') + ': ' + $t(data.row.type) }}
      </p>
    </q-td>

    <q-td
        key="shop"
        :props="data"
    >
      <strong
          v-if="data.row._embedded.shop"
          style="max-width: 140px; word-wrap: break-word; white-space: normal !important;"
      >
        {{ data.row._embedded.shop.name }} ({{ data.row._embedded.shop.id }})
      </strong>

      <div v-if="ifRecipientEnabled">
        <p
            v-if="data.row._embedded.profile"
            class="q-my-none"
        >
          {{ data.row._embedded.profile.name }}

          <span v-if="data.row._embedded.profile.surname">
          {{ data.row._embedded.profile.surname }}
        </span>
        </p>

        <p
            v-else
            class="q-my-none"
        >
          --
        </p>

        <p
            v-if="data.row._embedded.phone"
            class="q-my-none"
        >
          {{ data.row._embedded.phone.phone }}
        </p>

      </div>
    </q-td>

    <q-td
        key="details"
        :props="data"
    >
      <div style="max-width: 350px">
        <super-dropdown :model="dropdownModel"/>

        <q-tooltip class="q-px-sm text-left" v-if="data.row.orderErrors">
          <ul class="q-ma-none q-pl-md" style="max-width: 350px">
            <li class="text-caption text-weight-light" v-for="(orderError, i) in getOrderErrors" :key="i">
              {{ orderError }}
            </li>
          </ul>
        </q-tooltip>
      </div>

      <ul class="q-mt-sm q-pa-none">
        <li>
          <span>{{ $t('Order price') + ': ' }}</span>

          <strong>{{ data.row.orderPrice || 0 }}</strong>
        </li>

        <li>
          <span>{{ $t('Payment state') + ': ' }}</span>

          <strong>{{ data.row.paymentState || '--' }}</strong>
        </li>

        <li v-if="data.row.shipmentDate">
          <span>{{ $t('Shipment date') + ': ' }}</span>

          <strong>{{ $moment(data.row.shipmentDate).format(appOptions.formats.fullDate) }}</strong>
        </li>

        <li v-if="data.row._embedded.deliveryRequest && data.row._embedded.deliveryRequest.deliveryDate">
          <span>{{ $t('Delivery date') + ': ' }}</span>

          <strong>{{
              $moment(data.row._embedded.deliveryRequest.deliveryDate).format(appOptions.formats.fullDate)
            }}</strong>
        </li>

        <li v-if="data.row._embedded.source">
          <span>{{ $t('Source') + ': ' }}</span>

          <strong>{{ data.row._embedded.source.name + ` (${data.row._embedded.source.id})` }}</strong>
        </li>
      </ul>

      <q-token v-if="data.row.comment" style="white-space: pre-wrap !important; max-width: 350px;">
        {{ data.row.comment }}
      </q-token>
    </q-td>

    <q-td
        key="shipping"
        :props="data"
    >
      <div v-if="data.row._embedded.deliveryRequest">
        <q-badge
            v-if="hasDeliveryRequestStatus(data.row)"
            color="red"
            :floating="true"
        >
          <q-icon name="warning" size="2rem"/>

          <q-tooltip>
            {{ data.row._embedded.deliveryRequest.status }}
            <!--            <div-->
            <!--                v-for="(err, i) in data.row._embedded.deliveryRequest.status"-->
            <!--                :key="i"-->
            <!--                class="text-caption"-->
            <!--            >-->
            <!--              <q-item-section>-->
            <!--                {{ err }}-->
            <!--              </q-item-section>-->
            <!--            </div>-->
          </q-tooltip>
        </q-badge>

        <img
            v-if="data.row._embedded.deliveryRequest._embedded && data.row._embedded.deliveryRequest._embedded.deliveryService && data.row._embedded.deliveryRequest._embedded.deliveryService.logo"
            :src="checkLogo(data.row._embedded.deliveryRequest)"
            :title="data.row._embedded.deliveryRequest._embedded.deliveryService.name"
            style="width: 80px; height: 50px; object-fit: contain;"
        >

        <h6
            v-if="data.row._embedded.deliveryRequest._embedded && data.row._embedded.deliveryRequest._embedded.deliveryService && !data.row._embedded.deliveryRequest._embedded.deliveryService.logo"
            class="q-my-none"
        >
          {{ data.row._embedded.deliveryRequest._embedded.deliveryService.name }}
        </h6>

        <div v-if="data.row._embedded.deliveryRequest.state" style="max-width: 200px">
          <super-dropdown :model="drDropdownModel"/>
        </div>

        <h5
            v-if="data.row._embedded.deliveryRequest.trackingNumber"
            class="q-ma-none text-subtitle1"
        >
          <span
              @click="doCopy(data.row._embedded.deliveryRequest.trackingNumber)"
              class="doc-link cursor-pointer q-mb-md"
          >
            #{{ data.row._embedded.deliveryRequest.trackingNumber }}
          </span>
        </h5>

        <ul class="q-mt-sm q-pa-none">
          <li class="q-mb-xs">
            <span>{{ $t('ID') + ': ' }}</span>

            <strong>{{ data.row._embedded.deliveryRequest.id }}</strong>
          </li>

          <li v-if="data.row._embedded.deliveryRequest.estimatedCost > 0" class="q-mb-xs">
            <span>{{ $t('Estimated cost') + ': ' }}</span>

            <q-badge
                class="q-pa-xs"
                color="positive"
            >
              {{ data.row._embedded.deliveryRequest.estimatedCost + ' ' }}
              <span v-if="hasDeliveryRequestCurrency">{{
                  data.row._embedded.deliveryRequest._embedded.currency.symbol
                }}</span>
            </q-badge>
          </li>

          <li v-if="data.row._embedded.deliveryRequest.retailPrice > 0" class="q-mb-xs">
            <span>{{ $t('Delivery price') + ': ' }}</span>

            <q-badge
                class="q-pa-xs"
                color="secondary"
            >
              {{ data.row._embedded.deliveryRequest.retailPrice + ' ' }}
              <span v-if="hasDeliveryRequestCurrency">{{
                  data.row._embedded.deliveryRequest._embedded.currency.symbol
                }}</span>
            </q-badge>
          </li>

          <li v-if="data.row._embedded.deliveryRequest.price> 0" class="q-mb-xs">
            <span>{{ $t('Delivery by rate price') + ': ' }}</span>

            <q-badge
                class="q-pa-xs"
                :color="paymentStateColors[data.row._embedded.deliveryRequest.paymentState]"
            >
                <span>{{ data.row._embedded.deliveryRequest.price }}</span>

                <span v-if="hasDeliveryRequestCurrency">
                    {{data.row._embedded.deliveryRequest._embedded.currency.symbol }}
                </span>
            </q-badge>
          </li>

          <li v-if="data.row._embedded.deliveryRequest.payment > 0" class="q-mb-xs">
            <span>{{ $t('Cash on delivery') + ': ' }}</span>

            <q-badge
                class="q-pa-xs"
                :color="paymentStateColors[data.row._embedded.deliveryRequest.paymentState]"
            >
              <span>{{ data.row._embedded.deliveryRequest.payment }}</span>

              <span v-if="hasDeliveryRequestCurrency">{{
                  data.row._embedded.deliveryRequest._embedded.currency.symbol
                }}</span>
            </q-badge>
          </li>

          <li>
            <span>{{ $t('Total') }}: </span>

            <q-badge
                class="q-pa-xs"
                color="light-blue-9"
                :label="(Number(data.row._embedded.deliveryRequest.estimatedCost || 0) + Number(data.row._embedded.deliveryRequest.retailPrice || 0)).toFixed(2)"
            />
          </li>
        </ul>
      </div>
    </q-td>
  </q-tr>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Helpers
import { getStateColors } from '../../helpers/helpers'
import deliveryRequestStatusMatrix from './../../config/DeliveryRequestMatrix'
import orderStatusMatrix from './../../config/OrderMatrix'

// Components
import SuperDropdown from '../super-dropdown/SuperDropdown'

// Utils
import { doCopy } from './../../utils/copy-utils'

export default {
  name: 'OrderTableRow',
  emits: ['dbclick', 'check'],
  components: {
    SuperDropdown
  },
  props: {
    items: {
      type: Array,
      default () {
        return []
      }
    },
    noCustomer: {
      type: Boolean,
      default () {
        return false
      }
    },
    data: {
      type: Object,
      required: true
    },
    row: {
      type: Object,
      default () {
        return {}
      }
    },
    column: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      drStatuses: deliveryRequestStatusMatrix,
      statuses: orderStatusMatrix,
      stateColors: getStateColors(orderStatusMatrix),
      drStateColors: getStateColors(deliveryRequestStatusMatrix),
      paymentStateColors: {
        missing: 'default',
        payment_waiting: 'warning',
        received: 'info',
        partly_payed: 'warning',
        payed: 'success'
      }
    }
  },
  computed: {
    ...mapGetters([
      'lockedOrders',
      'deliveryServices',
      'appOptions'
    ]),
    ifRecipientEnabled () {
      return this.appOptions.orders?.enableRecipient
    },
    drDropdownModel () {
      const deliveryRequest = this.row._embedded.deliveryRequest

      if (!deliveryRequest) {
        return null
      }

      const option = {
        id: 'statuses',
        type: 'super-dropdown',
        variant: 'link',
        color: 'default',
        disabledLabels: true,
        editableFields: false,
        onClick: (state) => {
          this.saveDeliveryRequestByPayload({ id: deliveryRequest.id, data: { state } })
              .then(item => {
                this.setOrderFromList({
                  ...this.row,
                  _embedded: {
                    ...this.row._embedded,
                    deliveryRequest: item
                  }
                })
              })
        },
        value: { id: 'pending_queued', title: 'Pending Queued' },
        options: this.drStatuses
      }

      if (deliveryRequest && deliveryRequest.state !== option.value.id) {
        this.drStatuses.find(group => {
          let status = group.buttons.find(btn => {
            return btn.id === deliveryRequest.state
          })

          if (status) {
            option.color = group.color
            option.value = status
          }

          return status
        })
      }

      return option
    },
    dropdownModel () {
      const option = {
        id: 'statuses',
        type: 'super-dropdown',
        variant: 'link',
        color: 'default',
        editableFields: false,
        disabledLabels: true,
        onClick: (state) => {
          this.$service.order.save({ state }, this.row.id, this.row.type)
              .then(item => {
                this.setOrderFromList(item)
              })
        },
        value: this.statuses[0].buttons[0],
        options: this.statuses
      }

      this.statuses.find(group => {
        let status = group.buttons.find(btn => {
          return btn.id === this.row.state
        })

        if (status) {
          option.color = group.color
          option.value = status
        }

        return status
      })

      return option
    },
    isLockedOrder () {
      return this.lockedOrders.includes(this.row.id)
    },
    hasDeliveryRequestCurrency () {
      return this.row._embedded.deliveryRequest._embedded && this.row._embedded.deliveryRequest._embedded.currency
    },
    hasDeliveryServiceLogo () {
      return this.row._embedded.deliveryRequest._embedded &&
          this.loadCarrierByLink(this.row._embedded.deliveryRequest._embedded.deliveryService) &&
          this.loadCarrierByLink(this.row._embedded.deliveryRequest._embedded.deliveryService).logo
    },
    getOrderErrors () {
      if (Array.isArray(this.row.orderErrors)) {
        return this.row.orderErrors
      }

      return [
        this.row.orderErrors
      ]
    }
  },
  methods: {
    ...mapMutations([
      'setOrderFromList'
    ]),
    ...mapActions([
      'saveDeliveryRequestByPayload'
    ]),
    doCopy (content) {
      return doCopy(content)
    },
    handleDBClick () {
      this.$emit('dbclick', this.data.row)
    },
    baseName (str) {
      let base = str.substring(str.lastIndexOf('/') + 1)

      if (base.lastIndexOf('.') !== -1) {
        base = base.substring(0, base.lastIndexOf('.'))
      }

      return base
    },
    loadCarrierByLink (deliveryService) {
      if (!deliveryService) {
        return null
      }

      let id = this.baseName(deliveryService._links.self.href)

      return this.deliveryServices[id]
    },
    checkLogo (service) {
      if ((service._embedded.deliveryService || {}).logo.includes('http')) {
        return (service._embedded.deliveryService || {}).logo
      } else {
        return this.appOptions.defaultServer + service._embedded.deliveryService.logo
      }
    },
    handleCheck () {
      this.$emit('check', this.data.row)
    },
    hasDeliveryRequestStatus (data) {
      if (!Array.isArray(data?._embedded?.deliveryRequest?.status) && data?._embedded?.deliveryRequest?.status) {
        return true
      }

      if (Array.isArray(data?._embedded?.deliveryRequest?.status) && data?._embedded?.deliveryRequest?.status.length > 0) {
        return true
      }

      return false
    }
  }
}
</script>
