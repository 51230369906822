<template>
  <div>
    <q-card-section
      v-if="isLoading"
      class="row items-center justify-center"
      style="min-width: 50vw; min-height: 300px;"
    >
      <div class="text-center">
        <q-spinner
          color="primary"
          size="4rem"
          class="q-mb-md"
        />

        <div class="text-center text-subtitle1 text-bold q-py-sm">
          {{ `${loading} ${$t('of')} ${totalLoadings}` }}
        </div>
      </div>
    </q-card-section>

    <q-card-section v-else>
      <div
        class="text-center"
        :class="items.length > 0 ? 'row items-center justify-center' : ''"
      >
        <q-btn
          :label="$t('Browse file on your computer')"
          size="md"
          :style="items.length <= 0 ? 'min-height: 5rem;' : ''"
          color="light-blue-9"
          no-caps
          @click="handleBrowse"
        />

        <div class="row items-center justify-center q-my-md">
          <div
            style="text-decoration: underline;"
            class="clickable text-dark q-pa-md"
            @click="getExampleTable"
          >
            {{ $t('Download template') }}
          </div>

          <a
            :href="$instructions.order.import"
            class="clickable text-dark q-pa-md"
            target="blank"
          >
            {{ $t('Read instructions') }}
          </a>
        </div>

        <q-btn
          color="light-blue-9"
          text-color="white"
          size="md"
          :label="$t('Upload all products')"
          class="q-mr-sm"
          :disable="items.length <= 0"
          no-caps
          unelevated
          @click="saveAll"
        />
      </div>

      <q-file
        ref="fileInput"
        filled
        class="d-none"
        :label="$t('Pick File')"
        @update:model-value="handleTable"
      />

      <q-table
        v-if="items.length > 0"
        row-key="extId"
        class="full-width"
        :rows-per-page-label="$t('Rows per page')"
        :rows="items"
        :columns="columns"
        :pagination="{ page: 1, rowsPerPage: 250, totalRowsNumber: items.length }"
        virtual-scroll
      >
        <template v-slot:body="props">
          <order-import-row
            :table-props="props"
            @change="handleChange"
            @submit="handleSubmit"
            @save="save($event, props.rowIndex)"
          />
        </template>
      </q-table>
    </q-card-section>
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import OrderImportRow from './OrderImportRow.vue'

export default {
  name: 'OrderImport',
  emits: ['close'],
  components: {
    OrderImportRow
  },
  data () {
    return {
      loading: 0,
      totalLoadings: 0,
      isLoading: false,
      items: [],
      columns: [
        {
          label: this.$t('ExtId'),
          name: 'extId',
          align: 'left'
        },
        {
          label: this.$t('Source'),
          name: 'source',
          align: 'left'
        },
        {
          label: this.$t('Recipient'),
          name: 'recipient',
          align: 'left'
        },
        {
          label: this.$t('Order data'),
          name: 'orderData',
          align: 'left'
        },
        {
          label: this.$t('Payment State'),
          name: 'paymentState',
          align: 'left'
        },
        {
          label: this.$t('Total Price'),
          name: 'totalPrice',
          align: 'left'
        },
        {
          label: this.$t('Address'),
          name: 'address',
          align: 'left'
        },
        {
          label: this.$t('Params'),
          name: 'params',
          align: 'left'
        },
        {
          label: this.$t('Products'),
          name: 'products',
          align: 'left'
        },
        {
          label: this.$t('Actions'),
          name: 'actions',
          align: 'right'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'orders'
    ])
  },
  methods: {
    ...mapMutations([
      'setOrders',
      'addErrorNotification'
    ]),
    handleBrowse () {
      this.$refs.fileInput.pickFiles()
    },
    handleChange (update) {
      this.items = this.items.map(order => {
        return order.id === update.id
          ? update
          : order
      })
    },
    close () {
      this.$emit('close')
    },
    saveAll () {
      this.loading = 1
      this.totalLoadings = this.items.length
      this.isLoading = true

      return this.$service.import
        .processQueueWithOrders(
          this.items,
          () => {
            this.loading += 1
          }
        )
        .then(newItems => {
          this.items = []
          this.setOrders([
            ...newItems,
            ...this.orders
          ])
        })
        .finally(() => {
          this.isLoading = false
          this.totalLoadings = 0
          this.loading = 0
        })
    },
    save (row, index) {
      return this.$service.import.saveOrder(row)
        .then(item => {
          this.handleSubmit(index)
          this.setOrders([item, ...this.orders])
        })
    },
    getExampleTable () {
      return this.$service.import.getOrderExample()
    },
    handleTable (file) {
      if (!file) {
        return
      }

      return this.$service.import.loadFile(file, 'orders')
        .then(result => {
          this.totalLoadings = result.length
          this.loading = 1
          this.isLoading = true

          return this.$service.import.loadAddress(
            result,
            'locality',
            () => {
              this.loading += 1
            }
          )
        })
        .then(items => {
          this.totalLoadings = items.reduce((acc, order) => {
            return acc + order.products.length
          }, 0)

          this.loading = 1
          this.isLoading = true

          return this.$service.import.loadProductsForOrders(
            items,
            () => {
              this.loading += 1
            }
          )
        })
        .then(({ results, errors }) => {
          errors.forEach(err => {
            this.addErrorNotification(err)
          })
          this.totalLoadings = 0
          this.loading = 0
          this.isLoading = false
          this.items = results
        })
    },
    handleSubmit (index) {
      this.items = this.items.filter((o, i) => i !== index)
    }
  }
}
</script>

<style>
.locality-min-width {
  min-width: 180px;
}
</style>
